import { Container, YStack, Text, Paragraph } from '@holo/ui';

const AboutHoloBanner = () => {
  return (
    <Container backgroundColor="$base">
      <YStack space="$8" pt="$14" $xs={{ px: '$14' }} $md={{ pt: '$30', pb: '$5' }}>
        <YStack $md={{ flexDirection: 'row', px: 'unset' }} space="$4">
          <Text display="block" fontSize="$40" $md={{ flexBasis: `${(1 / 3) * 100}%` }}>
            About Holo
          </Text>

          <YStack
            flex={1}
            space="$4"
            $xs={{ flexDirection: 'row', space: '$8' }}
            $md={{ flexBasis: `${(1 / 3) * 100}%` }}
          >
            <Paragraph lineHeight="$2" $xs={{ flex: 1, flexBasis: 0 }}>
              For the past 12,000 years, the Earth has experienced a stable climate period, which has allowed humanity
              to develop agriculture, civilizations, and many other hallmarks of modern life. This geological epoch is
              called the Holocene. The vast amount of CO2 we have released into the atmosphere since the start of the
              Industrial Revolution is now threatening the very existence of the Holocene; we are on a clear path to
              enter a new human-made epoch with catastrophic consequences. Roughly 20% of the CO2 released into the
              atmosphere comes from the degradation of our agricultural lands.
            </Paragraph>
            <Paragraph lineHeight="$2" $xs={{ flex: 1, flexBasis: 0 }}>
              At holo, our mission is to contribute to the fight to preserve the Holocene by building the digital
              infrastructure needed to scale up the transition to regenerative agriculture. We believe that to achieve
              this, it is vital that we develop a farm operating system that has the digital tools necessary to make
              diversified regenerative farming cost efficient and to build a marketplace that makes it easy for all
              types of buyers to source from farmers with fields that have verified high carbon levels. Please reach out
              if you want to join us in our mission to preserve the Holocene by building a regenerative world.
            </Paragraph>
          </YStack>
        </YStack>
      </YStack>
    </Container>
  );
};

export default AboutHoloBanner;
