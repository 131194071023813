import { YStack, XStack, Text, Image, ProductListItem } from '@holo/ui';
import { Link } from '@holo/link';
import { memo, useState } from 'react';
import type { ProducerPopularFragmentFragment, ProductVariantType } from '@holo/gql';
import { useCart, useCarts } from '@/hooks';

type ProductVariant = {
  id: string;
  price: number;
  unit: {
    name: string;
    quantity: number;
  };
  name?: string;
  type: ProductVariantType;
};

type EnhancedProductItemProps = {
  variants: ProductVariant[];
  name: string;
  image?: string;
  href: string;
  storeId: string;
  category: string;
  subCategory: string;
  lastItem: boolean;
};

const EnhancedProductItem = memo(
  ({ image, name, variants, href, storeId, category, subCategory, lastItem }: EnhancedProductItemProps) => {
    const { addItem, removeItem, updateItem } = useCart({ storeId, skipCartQuery: true });
    const { refetch: refetchCarts } = useCarts();

    const [variantId, setVariantId] = useState(variants[0]?.id || '');
    const [quantity, setQuantity] = useState(0);

    const selectedVariant = variants.find((variant) => variant.id === variantId);

    if (!selectedVariant) {
      return null;
    }

    const handleOnChangeVariant = (id: string) => {
      if (variantId !== id) {
        setQuantity(0);
      }
      setVariantId(id);
    };

    const handleOnAddItem = () => {
      setQuantity(1);
      addItem(
        {
          id: selectedVariant.id,
          image,
          name,
          variantName: selectedVariant?.name,
          quantity: 1,
          unitQuantity: selectedVariant.unit.quantity,
          unit: selectedVariant.unit.name,
          price: selectedVariant.price,
          category,
          subCategory,
          type: selectedVariant.type,
        },
        {
          onCompleted: () => {
            refetchCarts();
          },
        },
      );
    };

    const handleUpdateQuantity = (value: number) => {
      setQuantity(value);
      if (value > 0) {
        updateItem({ id: selectedVariant.id, quantity: value }, { onCompleted: () => refetchCarts() });
      } else {
        removeItem(selectedVariant.id, { onCompleted: () => refetchCarts() });
      }
    };

    return (
      <ProductListItem
        gridLayout={false}
        name={name}
        image={image}
        variant={variants?.[0]}
        href={href}
        quantity={quantity}
        onAddItem={handleOnAddItem}
        onChangeQuantity={handleUpdateQuantity}
        onChangeVariant={handleOnChangeVariant}
        variantId={variantId}
        noBottomBorder={lastItem}
      />
    );
  },
);

type Props = {
  producer: ProducerPopularFragmentFragment;
};

const PopularProducerProducts = ({ producer }: Props) => {
  return (
    <YStack flex={1} borderRadius="$2" overflow="hidden" borderWidth={1} borderColor="$border" p="$5">
      <XStack alignItems="center" space="$2">
        <Image src={producer?.logo?.src} alt={producer.name} width={8 * 5} height={8 * 5} borderRadius="50%" />

        <YStack flexShrink={1}>
          <Text fontSize="$14" fontWeight="$semi-bold">
            {producer.name}
          </Text>

          {(producer.city || producer.province) && (
            <Text fontSize="$14" color="$darkGray">
              {producer.city && producer.city}
              {producer.province && `, ${producer.province}`}
            </Text>
          )}
        </YStack>

        <Link href={`/${producer.slug}`} style={{ marginLeft: 'auto', flexShrink: 0 }}>
          <Text textDecorationLine="underline">Show more</Text>
        </Link>
      </XStack>

      <YStack mt="$8" tag="ul">
        {producer.products.edges.map((p, i) => (
          <EnhancedProductItem
            key={p?.node?.id}
            name={p?.node?.name!}
            image={p?.node?.image?.src ?? undefined}
            // @ts-ignore
            variants={p?.node?.variants?.map((v) => ({ ...v, price: v.price?.retail }))!}
            href={`/${producer.slug}/product/${p?.node?.id!}`}
            storeId={producer.slug}
            category={p?.node?.category?.name!}
            subCategory={p?.node?.subCategory?.name!}
            lastItem={producer.products.edges.length - 1 === i}
          />
        ))}
      </YStack>
    </YStack>
  );
};

export default PopularProducerProducts;
