import { XStack, YStack, Text, Image } from '@holo/ui';
import { Link } from '@holo/link';

export type Producer = {
  id: string;
  image: {
    src: string;
    alt: string;
    base64?: string | null;
  };
  name: string;
  city?: string;
  province?: string;
  slug: string;
};

type Props = {
  variant?: 'primary' | 'secondary';
  priorityImage?: boolean;
  image: {
    src: string;
    alt: string;
    base64?: string | null;
    image?: string;
  };
  name: string;
  city?: string;
  province?: string;
  slug: string;
};

const ProducerCard = ({ variant = 'primary', priorityImage = false, image, name, city, province, slug }: Props) => {
  return (
    <YStack flex={1} borderRadius="$4" overflow="hidden" borderWidth={1} borderColor="$border" backgroundColor="$base">
      <Link href={`/${slug}`}>
        <Image
          src={image.src}
          alt={image.alt}
          blurDataURL={image?.base64}
          width={700}
          height={460}
          objectFit="cover"
          wrapperProps={{ height: 250 }}
          priority={priorityImage}
          style={{ width: '100%', height: '100%', objectPosition: 'top' }}
        />
      </Link>
      <XStack p="$6" justifyContent="space-between" alignItems="center">
        <YStack space="$1" mr="$2" flex={1}>
          <Text fontSize="$20" fontWeight="$medium">
            {name}
          </Text>
          {(city || province) && (
            <Text fontSize="$14">
              {city}
              {province && `, ${province}`}
            </Text>
          )}
        </YStack>

        <YStack>
          <Link href={`/${slug}`}>
            <Text
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize="$12"
              backgroundColor={variant === 'primary' ? '$contrast' : '$subtlePale'}
              color={variant === 'primary' ? '$base' : '$contrast'}
              height={8 * 4}
              px="$4"
              fontWeight="$medium"
              borderRadius={10000}
              hoverStyle={{
                backgroundColor: variant === 'primary' ? '$contrastHover' : '$subtlePaleHover',
              }}
            >
              Shop now
            </Text>
          </Link>
        </YStack>
      </XStack>
    </YStack>
  );
};

export default ProducerCard;
