import { useEffect, useReducer } from 'react';

const getNextCharacter = (completeWord: string, incompleteWord: string) => {
  const incompleteWordAtIndex = incompleteWord.length - 1;
  const nextCharacter = completeWord[incompleteWordAtIndex + 1];

  if (!nextCharacter) {
    return null;
  }

  return nextCharacter;
};

const removeCharacter = (word: string) => word.slice(0, word.length - 1);
const getCurrentWord = (words: string[], wordIndex: number) => words[wordIndex] ?? words[0];

type TypewriterStatus = 'typing' | 'deleting' | 'wroteWord' | 'deletedWord';

type TypewriterState = {
  words: Array<string>;
  currentWord: string;
  typedWord: string;
  wordIndex: number;
  status: TypewriterStatus;
};

type TypewriterAction =
  | { type: 'WRITE_CHAR' }
  | { type: 'DELETE_CHAR' }
  | { type: 'BEGIN_NEW_WORD' }
  | { type: 'BEGIN_DELETING_WORD' };

const reducer = (state: TypewriterState, action: TypewriterAction): TypewriterState => {
  switch (action.type) {
    case 'WRITE_CHAR': {
      const nextCharacter = getNextCharacter(state.currentWord, state.typedWord);

      if (nextCharacter === null) {
        return {
          ...state,
          status: 'wroteWord',
        };
      }

      return {
        ...state,
        typedWord: state.typedWord + getNextCharacter(state.currentWord, state.typedWord),
      };
    }

    case 'DELETE_CHAR': {
      const isBeginningOfWord = state.typedWord.length === 0;

      if (isBeginningOfWord) {
        return {
          ...state,
          status: 'deletedWord',
        };
      }

      return {
        ...state,
        typedWord: removeCharacter(state.typedWord),
      };
    }

    case 'BEGIN_NEW_WORD': {
      const newWordIndex = state.wordIndex + 1 > state.words.length - 1 ? 0 : state.wordIndex + 1;

      return {
        ...state,
        status: 'typing',
        typedWord: '',
        wordIndex: newWordIndex,
        currentWord: getCurrentWord(state.words, newWordIndex),
      };
    }

    case 'BEGIN_DELETING_WORD': {
      return {
        ...state,
        status: 'deleting',
      };
    }

    default:
      return state;
  }
};

const timeout = (delay: number) =>
  new Promise((r) => {
    setTimeout(r, delay);
  });

const useTypeWritingAnimation = (words: string[]) => {
  const [state, dispatch] = useReducer(reducer, {
    words,
    currentWord: words[0],
    typedWord: words[0],
    wordIndex: 0,
    status: 'wroteWord',
  });

  useEffect(() => {
    const writerIntervalId = setInterval(() => {
      if (state.status === 'typing') {
        dispatch({ type: 'WRITE_CHAR' });
      }
    }, 50);

    const deleterIntervalId = setInterval(() => {
      if (state.status === 'deleting') {
        dispatch({ type: 'DELETE_CHAR' });
      }
    }, 35);

    const waiterTimeoutId = setTimeout(async () => {
      if (state.status === 'wroteWord') {
        await timeout(1000);
        dispatch({ type: 'BEGIN_DELETING_WORD' });
      }

      if (state.status === 'deletedWord') {
        // await timeout(5000);
        dispatch({ type: 'BEGIN_NEW_WORD' });
      }
    }, 300);

    return () => {
      clearInterval(writerIntervalId);
      clearInterval(deleterIntervalId);
      clearTimeout(waiterTimeoutId);
    };
  }, [state, dispatch]);

  return state.typedWord;
};

export default useTypeWritingAnimation;
