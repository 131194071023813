import { Container, H1, Paragraph, YStack, Text, XStack, Button } from '@holo/ui';
import Image from 'next/image';
import landingHeroImage from 'public/img/landing-hero.png';
import useTypeWritingAnimation from '@/hooks/useTypeWritingAnimation';
import { Link } from '@holo/link';

const LandingHero = () => {
  const word = useTypeWritingAnimation(['you', 'your restaurant', 'your school', 'your hospital', 'your company']);

  return (
    <Container>
      <YStack
        tag="section"
        alignItems="center"
        $md={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        space="$12"
        py="$20"
      >
        <YStack flexBasis="50%">
          <H1 fontSize="$42" $xs={{ fontSize: '$52' }} $md={{ fontSize: '$72' }}>
            Reconnecting
            <br />
            <Text pr="$1" fontSize="inherit" borderColor="$accent" borderRightWidth="$1">
              {word}
            </Text>
            <br />
            to the source
          </H1>

          <Paragraph mt="$3" fontSize="$18" lineHeight={24} color="$contrastPale">
            Join our mission to reconnect the world to the source and to build a regenerative future.
          </Paragraph>

          <XStack space="$4" mt="$6">
            <Link href="/signup">
              <Button size="lg">Get started</Button>
            </Link>
            <Button variant="secondary" size="lg">
              <Link href="mailto:hello@holo.ag">Contact us</Link>
            </Button>
          </XStack>
        </YStack>

        <Image
          priority
          src={landingHeroImage}
          alt="Field and a product image"
          style={{
            width: '100%',
            maxWidth: 8 * 80,
            height: 'auto',
            filter: 'drop-shadow(-18px 15px 39px rgba(45, 105, 54, 0.4))',
          }}
        />
      </YStack>
    </Container>
  );
};

export default LandingHero;
