import { Container, YStack, Text, Paragraph } from '@holo/ui';

const CarbonPositiveBanner = () => {
  return (
    <YStack backgroundColor="$contrast">
      <Container>
        <YStack space="$8" py="$14" $xs={{ px: '$14' }} $md={{ py: '$30' }}>
          <YStack $md={{ flexDirection: 'row', px: 'unset' }} space="$4">
            <Text display="block" color="$base" fontSize="$40" $md={{ flexBasis: `${(1 / 3) * 100}%` }}>
              The worlds first
              <br />
              <Text fontSize="inherit" color="$accentDeep">
                carbon positive
              </Text>
              <br />
              produce
            </Text>

            <YStack
              flex={1}
              space="$4"
              $xs={{ flexDirection: 'row', space: '$8' }}
              $md={{ flexBasis: `${(1 / 3) * 100}%` }}
            >
              <Paragraph lineHeight="$2" color="$basePale" $xs={{ flex: 1, flexBasis: 0 }}>
                According to the U.N., the most promising solution for reducing CO2 in the atmosphere is to restore soil
                health and thus its ability to sequester carbon. Today more than 50% of our arable lands have been
                either moderately or severely degraded by industrialized farming practices. Reversing this trend will be
                one of the most important tasks in the fight against global warming, the loss of biodiversity, and our
                ability to feed the world. To achieve this, we need to start rewarding farmers that work hard every day
                to improve the soil health in their lands.
              </Paragraph>
              <Paragraph lineHeight="$2" color="$basePale" $xs={{ flex: 1, flexBasis: 0 }}>
                We are therefore incredibly proud to offer the world’s first method for sourcing produce that has grown
                in fields with verified high carbon levels. The verification process has been developed in collaboration
                with soil scientists at Cornell University and we are planning to make Carbon Positive Produce available
                for sale in Q2 2023. To learn more, please{' '}
                <a href="https://calendly.com/holoag/30min" target="_blank" rel="noopener noreferrer">
                  <Text textDecorationLine="underline">book a meeting with us</Text>
                </a>
                .
              </Paragraph>
            </YStack>
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default CarbonPositiveBanner;
