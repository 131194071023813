import { useProducersFeaturedQuery } from '@holo/gql';
import { Container, H2, YStack } from '@holo/ui';
import ProducerCard from '@/components/ProducerCard/ProducerCard';

const FeaturedProducers = () => {
  const { data, loading } = useProducersFeaturedQuery();

  if (loading || !data) {
    return null;
  }

  return (
    <Container py="$16">
      <YStack tag="section">
        <H2>Featured Producers</H2>

        <YStack mt="$4" className="landing-page-grid-container">
          {data?.producersFeatured.edges.map((p) => (
            <ProducerCard
              key={p?.node?.id}
              priorityImage
              name={p?.node?.name || ''}
              image={{
                src: p?.node?.image?.src || '',
                base64: p?.node?.image?.base64 ?? null,
                alt: p?.node?.name || '',
              }}
              city={p?.node?.city || undefined}
              province={p?.node?.province || undefined}
              slug={p?.node?.slug || ''}
            />
          ))}
        </YStack>
      </YStack>
    </Container>
  );
};

export default FeaturedProducers;
