import { useProducersPopularQuery } from '@holo/gql';
import { Container, H2, YStack } from '@holo/ui';
import PopularProducerProducts from '@/components/PopularProducerProducts/PopularProducerProducts';

const PopularProducers = () => {
  const { data, loading } = useProducersPopularQuery();

  if (loading || !data) {
    return null;
  }

  const producers = [data.mountain, data.norwich, data.tonjes];

  return (
    <Container py="$16">
      <YStack tag="section">
        <H2>Popular Products</H2>

        <YStack mt="$4" className="landing-page-grid-container">
          {producers.map((p) => (
            <PopularProducerProducts key={p.id} producer={p} />
          ))}
        </YStack>
      </YStack>
    </Container>
  );
};

export default PopularProducers;
