import { useProducersByLocationQuery } from '@holo/gql';
import { Container, YStack, H2 } from '@holo/ui';
import ProducerCard from '@/components/ProducerCard/ProducerCard';

const ProducersNearYou = () => {
  const { data, loading } = useProducersByLocationQuery();

  if (loading || !data) {
    return null;
  }

  return (
    <YStack backgroundColor="$subtlePale">
      <Container py="$16">
        <YStack tag="section">
          <H2>Shop from producers near you</H2>
          <YStack mt="$4" className="landing-page-grid-container">
            {data.producersByLocation.edges.map((p) => (
              <ProducerCard
                key={p?.node?.id}
                variant="secondary"
                image={{
                  src: p?.node?.image?.src || '',
                  base64: p?.node?.image?.base64,
                  alt: p?.node?.name || '',
                }}
                name={p?.node?.name || ''}
                city={p?.node?.city || undefined}
                province={p?.node?.province || undefined}
                slug={p?.node?.slug || ''}
              />
            ))}
          </YStack>
        </YStack>
      </Container>
    </YStack>
  );
};

export default ProducersNearYou;
