import { SizableText, styled } from 'tamagui';

const Paragraph = styled(SizableText, {
  tag: 'p',
  lineHeight: 'normal',
  fontSize: '$14',
  display: 'block',
});

export default Paragraph;
