import { serverSideTranslations } from '@holo/i18n/serverSideTranslations';
import type { GetStaticProps } from 'next';
import SEO from '@holo/seo';
import LandingHero from '@/components/LandingHero/LandingHero';
import FeaturedProducers from '@/components/FeaturedProducers/FeaturedProducers';
import ProducersNearYou from '@/components/ProducersNearYou/ProducersNearYou';
import PopularProducers from '@/components/PopularProducers/PopularProducers';
import CarbonPositiveBanner from '@/components/CarbonPositiveBanner/CarbonPositiveBanner';
import AboutHoloBanner from '@/components/AboutHoloBanner/AboutHoloBanner';
import { addApolloCache, initApolloClient } from '@holo/client';
import {
  ProducersFeaturedDocument,
  ProducersByLocationDocument,
  ProducersPopularDocument,
  type ProducersFeaturedQuery,
  type ProducersByLocationQuery,
  type ProducersPopularQuery,
} from '@holo/gql';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const apolloClient = initApolloClient();

  await apolloClient.query<ProducersFeaturedQuery>({
    query: ProducersFeaturedDocument,
  });

  await apolloClient.query<ProducersByLocationQuery>({
    query: ProducersByLocationDocument,
  });

  await apolloClient.query<ProducersPopularQuery>({
    query: ProducersPopularDocument,
  });

  return addApolloCache(apolloClient, {
    props: await serverSideTranslations(locale),
    revalidate: 60 * 30, // 30 minutes
  });
};

const Index = () => {
  return (
    <>
      <SEO title="The future of agriculture" />
      <LandingHero />
      <FeaturedProducers />
      <ProducersNearYou />
      <PopularProducers />
      <CarbonPositiveBanner />
      <AboutHoloBanner />
    </>
  );
};

export default Index;
